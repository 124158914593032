import { RouteComponentProps, navigate } from "@reach/router";
import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { sanitizeUrl } from "@braintree/sanitize-url";

import { WEB_APP_HOSTNAMES, MOBILE_APP_SCHEMES } from "../config.json";

const REDIRECT_PATH = "/redir";

interface Props extends RouteComponentProps {}

const validateRedirectURL = (redirString: string) => {
  try {
    return [...WEB_APP_HOSTNAMES, ...MOBILE_APP_SCHEMES].find((str) => {
      const { hostname, protocol } = new URL(redirString);
      return str === hostname || str === protocol;
    });
  } catch (err) {
    return false;
  }
};

export default function AppRedirect(props: Props) {
  const [redirSite, setRedirSite] = useState("");

  // ensure that there is an appropriate stem such as exp:// or https:// etc otherwise there will be infinite looping
  useEffect(() => {
    const redirString = getRedirSite();
    const link = document.getElementById("redirLink");

    if (link) {
      link.setAttribute("href", redirString);
      link.click();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Extracts redirect URL based on location path.
   * @returns string - extracted/parsed redirect URL
   */
  const getRedirSite = () => {
    const extractedString =
      props.location?.href.replace(
        `${props.location.origin}${REDIRECT_PATH}`,
        ""
      ) || "";
    const isFromErp = extractedString.startsWith("?");
    const redirString = extractedString.slice(1);

    let url = redirString;

    if (isFromErp) {
      const { state, ...restURI } = queryString.parse(redirString);

      url = queryString.stringifyUrl({ url: state as string, query: restURI });
    }

    const sanitizedUrl = sanitizeUrl(url);

    if (sanitizedUrl === "about:blank" || !validateRedirectURL(sanitizedUrl)) {
      navigate("/not-valid");
      return "";
    }

    setRedirSite(sanitizedUrl);

    return sanitizedUrl;
  };

  return (
    <div>
      <h1>Redirecting...</h1>
      <a href={redirSite} id="redirLink">
        Click here if you are not redirected automatically
      </a>
    </div>
  );
}
